import React from 'react';
import { PageProps, StaticQuery, graphql } from 'gatsby';

import { withTranslation, WithTranslation } from 'react-i18next';
import GatsbyImage from 'gatsby-image';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import Link from '../../../components/Link/Link';
import { useLocaleContext } from '../../../context/Locale';
import './style.scss';
import videos, { Video } from '../../../data/video/videos';
import { getPathFromRouteKey } from '../../../shared/utils/getPathFromRouteKey';
import { defaultLocaleTag } from '../../../../config/locales';

type Props = PageProps & WithTranslation;

const ThumbnailImage = (props: { fileName: string; alt: string }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.fileName);
      });
      if (!image) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            `Cannot find the image with name '${props.fileName}' in the '/src/assets/images' folder. Did you ensure it exists and the gatsby-config still points to the '/src/assets/images' path? `
          );
        }

        return null;
      }

      return (
        <GatsbyImage
          alt={props.alt}
          fluid={image.node.childImageSharp.fluid}
          imgStyle={{
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          style={{ position: 'relative', width: '100%' }}
        />
      );
    }}
  />
);
const VideoLibrary: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();
  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const getVideoColumn = (data: Video[]) => {
    return (
      <>
        {data.map((block, i) => {
          return (
            <div className="column column-33" key={`${block.titleKey}-${i}-${activeLocaleTag}`}>
              <Link to={getPathFromRouteKey(block.path[defaultLocaleTag])}>
                <ThumbnailImage
                  fileName={block.thumbnailsFileName[activeLocaleTag]}
                  alt={t(block.titleKey)}
                />
              </Link>
              <p>{t(block.titleKey)}</p>
            </div>
          );
        })}
        {[...Array(3 - (data.length % 3))].map((item, index) => (
          <div aria-hidden="true" className="column column-33" key={`empty-${index}`} />
        ))}
      </>
    );
  };

  const getVideoRows = (vids: Video[]) => {
    const generatedRows = [];
    for (let i = 0; i <= vids.length; i += 3) {
      const firstItem = vids[i];
      const secondItem = vids[i + 1];
      const thirdItem = vids[i + 2];
      const finalRowItem = [];

      if (firstItem) {
        finalRowItem.push(firstItem);
        if (secondItem) {
          finalRowItem.push(secondItem);
          if (thirdItem) {
            finalRowItem.push(thirdItem);
          }
        }
      }

      if (finalRowItem.length) {
        generatedRows.push(
          <div className="row row-wrap VideoLibrary__video-row" key={`rows-${i}`}>
            {getVideoColumn(finalRowItem)}
          </div>
        );
      }
    }

    return generatedRows;
  };

  const videoCategories: any = {
    brokerPortal: {
      title: t('categories.brokerPortal'),
      videos: [],
    },
    caseStudies: {
      title: t('categories.caseStudies'),
      videos: [],
    },
    dealSubmissionTips: {
      title: t('categories.dealSubmissionTips'),
      videos: [],
    },
    eliteLoyalty: {
      title: t('categories.eliteLoyalty'),
      videos: [],
    },
  };

  videos.forEach((video) => {
    videoCategories[video.categoryKey].videos.push(video);
  });

  return (
    <Layout options={layoutOptions} className="VideoLibrary">
      <div className="grid-container">
        <div className="VideoLibrary__generic-gap">
          <p>{t('body')}</p>
        </div>
        {Object.keys(videoCategories).map((vc) => (
          <div className="VideoLibrary__generic-gap" key={vc}>
            <h2>{videoCategories[vc].title}</h2>
            {getVideoRows(videoCategories[vc].videos)}
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.VIDEO_LIBRARY)(VideoLibrary);
